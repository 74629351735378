import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { LucideProps } from 'lucide-react'
import { JSXElementConstructor } from 'react'
import Typography from '../Typography'

const emptyFunc = () => undefined

const StyledTextInputContainer = styled.div<{ isCellStyle: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;

    ${(p) =>
        p.isCellStyle &&
        `
            height: 100%;
            justify-content: center;
        `}

    .container__input {
        display: flex;
        position: relative;
        align-items: center;

        .container__input__icon {
            position: absolute;
            left: 1.2rem;
            color: ${DESIGN_TOKENS.text.textMutedForeground};
        }
    }
`

const StyledLabel = styled.label`
    color: ${DESIGN_TOKENS.text.textForeground};
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
`

const StyledTextInput = styled.input<{
    hasIcon: boolean
    hasError?: boolean
    isCellStyle: boolean
}>`
    width: 100%;
    height: 4rem;
    padding-left: ${(p) => (p.hasIcon ? '3.4rem' : '1.2rem')};
    padding-right: 1.2rem;
    padding-bottom: 0.1rem;

    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    color: ${(p) =>
        !p.hasError
            ? DESIGN_TOKENS.text.textForeground
            : DESIGN_TOKENS.text.textError};

    border-radius: 0.6rem;
    border: 0.1rem solid
        ${(p) => (!p.hasError ? DESIGN_TOKENS.input : DESIGN_TOKENS.error)};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::placeholder {
        color: ${(p) =>
            !p.hasError
                ? DESIGN_TOKENS.text.textMutedForeground
                : DESIGN_TOKENS.text.textError};
    }

    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${BASE_TOKENS.grey[0]},
            0 0 0 0.4rem ${BASE_TOKENS.grey[400]};
    }

    ${(p) =>
        p.isCellStyle &&
        `
        height: 100%;
        border: none;
        border-radius: 0;

        &:focus-visible {
            box-shadow: none;
        }
    `}
`

type TextInputProps = {
    isDisabled?: boolean
    isPassword?: boolean
    isCellStyle?: boolean
    label?: string
    value?: string
    placeholder?: string
    error?: string
    classes?: {
        input?: string
    }
    Icon?: JSXElementConstructor<LucideProps>
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onChange: (value: string, e?: React.ChangeEvent<HTMLInputElement>) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const TextInput = ({
    isDisabled = false,
    isPassword = false,
    isCellStyle = false,
    label,
    value,
    placeholder,
    error,
    classes,
    Icon,
    onBlur = emptyFunc,
    onChange = emptyFunc,
    onKeyDown = emptyFunc,
}: TextInputProps) => {
    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value, e)
    }

    return (
        <StyledTextInputContainer isCellStyle={isCellStyle}>
            {label && <StyledLabel>{label}</StyledLabel>}
            <div className="container__input">
                {Icon && (
                    <span className="container__input__icon">
                        <Icon
                            size={16}
                            color={
                                value
                                    ? DESIGN_TOKENS.text.textForeground
                                    : DESIGN_TOKENS.text.textMutedForeground
                            }
                        />
                    </span>
                )}
                <StyledTextInput
                    disabled={isDisabled}
                    hasIcon={!!Icon}
                    hasError={!!error}
                    isCellStyle={isCellStyle}
                    type={isPassword ? 'password' : 'text'}
                    value={value}
                    placeholder={placeholder}
                    className={classes?.input}
                    onBlur={onBlur}
                    onChange={_onChange}
                    onKeyDown={onKeyDown}
                />
            </div>
            {!!error && (
                <Typography variant="caption" color="error">
                    {error}
                </Typography>
            )}
        </StyledTextInputContainer>
    )
}

export default TextInput
