import { lazy } from 'react'
import { Route } from 'react-router-dom'
import PrivateRoute from 'routes/PrivateRoute'

const MotherComponent = lazy(
    () =>
        import(
            /* webpackChunkName: 'MotherComponent' */ 'modules/MotherComponent'
        )
)

const Payables = lazy(
    () => import(/* webpackChunkName: 'Payables' */ 'modules/Payables')
)

const Receivables = lazy(
    () => import(/* webpackChunkName: 'Receivables' */ 'modules/Receivables')
)

const Automations = lazy(
    () => import(/* webpackChunkName: 'Automations' */ 'modules/Automations')
)

const Settings = lazy(
    () => import(/* webpackChunkName: 'Settings' */ 'modules/Settings')
)

const Orders = lazy(
    () => import(/* webpackChunkName: 'Orders' */ 'modules/Orders')
)

const OrderDetails = lazy(
    () => import(/* webpackChunkName: 'OrderDetails' */ 'modules/OrderDetails')
)

const Customers = lazy(
    () => import(/* webpackChunkName: 'Customers' */ 'modules/Customers')
)

const Partners = lazy(
    () => import(/* webpackChunkName: 'Partners' */ 'modules/Partners')
)

const Lanes = lazy(
    () => import(/* webpackChunkName: 'Lanes' */ 'modules/Lanes')
)

const Users = lazy(
    () => import(/* webpackChunkName: 'Users' */ 'modules/Users')
)

const CustomerDetails = lazy(
    () =>
        import(
            /* webpackChunkName: 'CustomerDetails' */ 'modules/CustomerDetails'
        )
)

const PartnerDetails = lazy(
    () =>
        import(
            /* webpackChunkName: 'PartnerDetails' */ 'modules/PartnerDetails'
        )
)

const Wilson = lazy(
    () => import(/* webpackChunkName: 'Wilson' */ 'modules/Wilson')
)

const Inbox = lazy(
    () => import(/* webpackChunkName: 'Inbox' */ 'modules/Inbox')
)

export const OpsRoutes = () => {
    return (
        <Route element={<PrivateRoute />}>
            <Route element={<MotherComponent />}>
                <Route path="payables" element={<Payables />} />
                <Route path="receivables" element={<Receivables />} />
                <Route path="tasks" element={<Automations />} />
                <Route path="settings" element={<Settings />} />
                <Route path="orders" element={<Orders />} />
                <Route path="orders/:id" element={<OrderDetails />} />
                <Route path="customers" element={<Customers />} />
                <Route path="customers/:id" element={<CustomerDetails />} />
                <Route path="partners" element={<Partners />} />
                <Route path="partners/:id" element={<PartnerDetails />} />
                <Route path="lanes" element={<Lanes />} />
                <Route path="users" element={<Users />} />
                <Route path="inbox/:customerId/:chatId" element={<Inbox />} />
                <Route path="w/:customerId/:chatId" element={<Wilson />} />
            </Route>
        </Route>
    )
}
