import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLogin, useMagicLinkLogin } from 'mutations/user.mutations'
import { showToastError } from 'hooks/useToast'
import { LoginForm } from './components/LoginForm'
import Cookies from 'js-cookie'
import { User } from 'types/user.types'
import { isCustomerUser, isSuperAdmin } from 'utils/user.utils'
import { getCTSDK } from 'services/ctClient'
import { URLS } from 'utils/url.utils'
import { isValidEmail } from 'utils/email.utils'
import { TOKEN_KEY, TOKEN_REFRESH_KEY } from 'constants/app.constants'

const ctSDK = getCTSDK()

export const LoginFormContainer = () => {
    const [searchParams] = useSearchParams()
    const queryEmail = searchParams.get('email')
    const queryLoginLinkId = searchParams.get('loginToken')
    const redirectTo = searchParams.get('redirectTo')
    const navigate = useNavigate()
    const { isLoading: isSubmitting, mutateAsync: login } = useLogin()
    const { isLoading: isSubmittingMagicLink, mutateAsync: magicLinkLogin } =
        useMagicLinkLogin()
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')

    const _onGetUserAndNavigate = async () => {
        const meResp = await ctSDK.get<{ user: User }>('/users/me')
        const me = meResp.data?.user

        if (redirectTo) {
            navigate(redirectTo)
            return
        } else if (isSuperAdmin(me)) {
            navigate(URLS.cartage.superAdmin.home())
            return
        } else if (isCustomerUser(me)) {
            navigate(URLS.cartage.shipperPortal.wilson('general'))
            return
        }

        navigate(URLS.cartage.home())
    }

    useEffect(() => {
        const func = async () => {
            try {
                const token = Cookies.get(TOKEN_KEY)
                if (!token) return

                const resps = await Promise.allSettled([
                    ctSDK.post('/users/validate'),
                    ctSDK.post('/users/sp/validate'),
                ])
                if (resps.some((r) => r.status === 'fulfilled')) {
                    await _onGetUserAndNavigate()
                }
            } catch (error) {
                // do nothing
            }
        }

        func()
    }, [])

    useEffect(() => {
        if (!queryEmail || !queryLoginLinkId) return

        const login = async () => {
            try {
                const resp = await magicLinkLogin({
                    email: queryEmail,
                    loginToken: queryLoginLinkId,
                })

                Cookies.set(TOKEN_KEY, resp.data?.token)
                Cookies.set(TOKEN_REFRESH_KEY, resp.data?.refreshToken)

                _onGetUserAndNavigate()
            } catch (error) {
                // do nothing
                navigate(URLS.cartage.login())
            }
        }

        login()
    }, [queryEmail, queryLoginLinkId])

    const _onLogin = async () => {
        const trimmedEmail = email.trim()

        try {
            if (!isValidEmail(trimmedEmail)) {
                setEmailError('Please enter a valid email')
                return
            }

            await login({ email: trimmedEmail })

            navigate(URLS.cartage.loginSuccess({ email: trimmedEmail }))
        } catch (error) {
            showToastError(error)
        }
    }

    const _onChangeEmail = (value: string) => {
        if (emailError) setEmailError('')
        setEmail(value)
    }

    return (
        <LoginForm
            isSubmitting={isSubmitting || isSubmittingMagicLink}
            email={email}
            emailError={emailError}
            onLogin={_onLogin}
            onChangeEmail={_onChangeEmail}
        />
    )
}
