import axios, { AxiosInstance } from 'axios'
import { TOKEN_KEY, TOKEN_REFRESH_KEY } from 'constants/app.constants'
import Cookies from 'js-cookie'

let ctSDK: AxiosInstance | undefined

const createCTSDK = (): AxiosInstance => {
    const baseUrl = process.env.REACT_APP_API_URL_V3

    const instance = axios.create({
        baseURL: baseUrl,
    })

    instance.interceptors.request.use(
        (config) => {
            const token = Cookies.get(TOKEN_KEY)

            config.headers['Content-Type'] = 'application/json'
            config.headers['Authorization'] = `Bearer ${token}`

            if (config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data'
            }

            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    instance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config

            if (error.response.status === 401 && !originalRequest._retry) {
                const refreshToken = Cookies.get(TOKEN_REFRESH_KEY)
                if (!refreshToken) {
                    return Promise.reject(error)
                }

                originalRequest._retry = true
                const { data } = await axios.post(
                    `${baseUrl}/auth/refreshToken`,
                    {
                        refreshToken,
                    }
                )

                const accessToken = data?.token
                if (!accessToken) {
                    return Promise.reject(error)
                }

                Cookies.set(TOKEN_KEY, accessToken)
                originalRequest.headers[
                    'Authorization'
                ] = `Bearer ${accessToken}`

                return axios(originalRequest)
            }

            return Promise.reject(error)
        }
    )

    return instance
}

export const getCTSDK = (): AxiosInstance => {
    if (ctSDK) {
        return ctSDK
    }

    ctSDK = createCTSDK()
    return ctSDK
}
