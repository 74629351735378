import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { getCTSDK } from 'services/ctClient'
import { URLS } from 'utils/url.utils'
import { TOKEN_KEY, TOKEN_REFRESH_KEY } from 'constants/app.constants'

const ctSDK = getCTSDK()

export const Logout = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const func = async () => {
            try {
                await ctSDK.post('/users/logout')
            } catch (error) {
                // do nothing
            } finally {
                Cookies.remove(TOKEN_KEY)
                Cookies.remove(TOKEN_REFRESH_KEY)

                navigate(URLS.cartage.login())
            }
        }

        func()
    }, [])

    return null
}
