import { TOKEN_KEY } from 'constants/app.constants'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import { useMemo } from 'react'
import { UserRole } from 'types/user.types'

/**
 * Hook to get the token claims from the auth token stored in the cookie.
 */
export const useTokenClaims = () => {
    return useMemo(() => {
        const token = Cookies.get(TOKEN_KEY) || ''
        return jwtDecode<{
            userId: string
            orgId: string
            customerId?: string
            role: UserRole
        }>(token)
    }, [])
}
