import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { LoadScript } from '@react-google-maps/api'
import { QueryClient, QueryClientProvider } from 'react-query'
import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary'
import ErrorBoundary from 'components/composite/ErrorBoundary'
import { ToastProvider } from 'providers/ToastProvider'
import { ShipperPortalRoutes } from 'shipperPortal/routes/ShipperPortalRoutes'
import { OpsRoutes } from 'routes/OpsRoutes'
import { SuperAdminRoutes } from 'superAdminPortal/routes/SuperAdminRoutes'
import { PublicRoutes } from 'publicPages/routes/PublicRoutes'
import { useLocalStorage } from 'hooks/useLocalStorage'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
})

const App = () => {
    const [error] = useErrorBoundary()
    const [lastErrorReloadAt, setLastErrorReloadAt] = useLocalStorage(
        'cartage.lastErrorReloadAt',
        0
    )

    if (error) {
        const now = new Date().getTime()
        if (now - lastErrorReloadAt < 5000) {
            // Only reload if haven't reloaded in the last 5 seconds.
            // This is to prevent a reload loop if the error is persistent.
            return <ErrorBoundary />
        }

        setLastErrorReloadAt(now)
        window.location.reload()
    }

    return (
        <QueryClientProvider client={queryClient}>
            <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
                libraries={['places']}
            >
                <BrowserRouter>
                    <Routes>
                        <Route
                            index
                            element={
                                <Navigate
                                    replace
                                    to="/orders"
                                    state={{ from: '/' }}
                                />
                            }
                        />

                        {SuperAdminRoutes()}

                        {PublicRoutes()}

                        {OpsRoutes()}

                        {ShipperPortalRoutes()}
                    </Routes>
                </BrowserRouter>
            </LoadScript>
            <ToastProvider />
        </QueryClientProvider>
    )
}

export default withErrorBoundary(App)
