import { Navigate, Route } from 'react-router-dom'
import { ShipperPortalRoute } from '../../routes/ShipperPortalRoute'
import { lazy } from 'react'

const MotherComponent = lazy(
    () =>
        import(
            /* webpackChunkName: 'MotherComponent' */ 'modules/MotherComponent'
        )
)
const ShipperPortalOrders = lazy(
    () =>
        import(
            /* webpackChunkName: 'ShipperPortalOrders' */ 'shipperPortal/modules/Orders'
        )
)
const Partners = lazy(
    () =>
        import(
            /* webpackChunkName: 'Partners' */ 'shipperPortal/modules/Partners'
        )
)
const PartnerDetails = lazy(
    () =>
        import(
            /* webpackChunkName: 'PartnerDetails' */ 'shipperPortal/modules/PartnerDetails'
        )
)
const OrderDetails = lazy(
    () =>
        import(
            /* webpackChunkName: 'OrderDetails' */ 'shipperPortal/modules/OrderDetails'
        )
)
const ShipperPortalPayables = lazy(
    () =>
        import(
            /* webpackChunkName: 'ShipperPortalPayables' */ 'shipperPortal/modules/Payables'
        )
)

const Wilson = lazy(
    () =>
        import(/* webpackChunkName: 'Wilson' */ 'shipperPortal/modules/Wilson')
)

const Settings = lazy(
    () =>
        import(
            /* webpackChunkName: 'Settings' */ 'shipperPortal/modules/Settings'
        )
)

const AddressBookEntries = lazy(
    () =>
        import(
            /* webpackChunkName: 'AddressBookEntries' */ 'shipperPortal/modules/AddressBookEntries'
        )
)

export const ShipperPortalRoutes = () => {
    return (
        <Route path="sp" element={<ShipperPortalRoute />}>
            <Route element={<MotherComponent />}>
                <Route
                    index
                    element={
                        <Navigate
                            replace
                            to="/sp/shipments"
                            state={{ from: '/' }}
                        />
                    }
                />
                <Route path="shipments" element={<ShipperPortalOrders />} />
                <Route path="shipments/:id" element={<OrderDetails />} />
                <Route path="partners" element={<Partners />} />
                <Route path="partners/:id" element={<PartnerDetails />} />
                <Route path="payables" element={<ShipperPortalPayables />} />
                <Route path="settings" element={<Settings />} />
                <Route path="addressBook" element={<AddressBookEntries />} />
                <Route path="w/:chatId" element={<Wilson />} />
            </Route>
        </Route>
    )
}
