import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const ANCHOR_TAG_COLORS = {
    primary: DESIGN_TOKENS.text.textForeground,
    secondary: DESIGN_TOKENS.text.textMutedForeground,
}

const ANCHOR_TAG_HOVER_COLORS = {
    primary: BASE_TOKENS.grey[600],
    secondary: BASE_TOKENS.grey[800],
}

export const AnchorTag = styled.a<{
    color: 'primary' | 'secondary'
    variant?: 'body2' | 'caption'
    underline?: boolean // Optional prop for underlining
}>`
    margin: 0;
    font-style: normal;
    font-family: Inter;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: -0.04rem;
    font-weight: 400;
    text-underline-offset: 0.4rem;
    cursor: pointer;

    transition: color 0.3s ease;

    &:hover {
        color: ${(p) => ANCHOR_TAG_HOVER_COLORS[p.color]};
    }

    color: ${(p) => ANCHOR_TAG_COLORS[p.color]};

    text-decoration: ${(p) =>
        p.underline
            ? 'underline'
            : 'none'}; // Apply underlining based on the prop

    ${(p) => {
        switch (p.variant) {
            case 'body2':
                return `
                    font-size: 1.4rem;
                    line-height: 2rem;
                    letter-spacing: -0.04rem;
                    font-weight: 400;
                `
            case 'caption':
                return `
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    letter-spacing: -0.02rem;
                    font-weight: 400;
                `
            default:
                return `
                    font-size: 1.4rem;
                    line-height: 2rem;
                    letter-spacing: -0.04rem;
                    font-weight: 400;
                `
        }
    }}
`
