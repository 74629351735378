import useLocalStorageExternal from 'use-local-storage'
import { useTokenClaims } from './useTokenClaims'
import { Dispatch, SetStateAction } from 'react'

const opts = {
    // By default 'use-local-storage' actively syncs react state through
    // events between tabs. This can result in unexpected behavior when multiple
    // tabs are open.
    syncData: false,
}

/**
 * Our wrapper around 'use-local-storage'. Disabled syncing between tabs by default.
 */
export const useLocalStorage = <T>(
    key: string,
    defaultValue: T
): [T, Dispatch<SetStateAction<T | undefined>>] => {
    return useLocalStorageExternal(key, defaultValue, opts)
}

/**
 * Local storage hook that automatically namespaced keys by the current user id and org id.
 * Can only be used in contexts where the user is authenticated. To be used to store user-specific
 * data. Namespacing is important because local storage is shared between orgs.
 */
export const useUserLocalStorage = <T>(
    key: string,
    defaultValue: T
): [T, Dispatch<SetStateAction<T | undefined>>] => {
    const { userId, orgId } = useTokenClaims()
    const namespacedKey = `${key}-${orgId}-${userId}`
    return useLocalStorage(namespacedKey, defaultValue)
}
