import * as EmailValidator from 'email-validator'
import { removeSpaces } from './string.utils'

export const isValidEmail = (email: string): boolean => {
    return EmailValidator.validate(email) && !email.endsWith('.con')
}

export const getEmailsArrFromEmailStr = (
    emailsStr?: string
): { isValid: boolean; emails: string[] } => {
    const emailsArr =
        emailsStr?.split(',')?.map(removeSpaces)?.filter(Boolean) || []

    const isValid = emailsArr.every((email) => isValidEmail(email))

    return { isValid, emails: emailsArr }
}
